import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {datadogRum} from '@datadog/browser-rum';

import {selectClinicName, userSelector} from '@/components/general/MHHeader/redux/selectors';

const UNKNOWN_VALUE = 'unknown';

export const useInitializeDatadogRum = () => {
    const user = useSelector(userSelector);
    const clinicName = useSelector(selectClinicName);
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (!isInitialized) {
            datadogRum.init({
                applicationId: window.DATADOG_APPLICATION_ID,
                clientToken: window.DATADOG_CLIENT_TOKEN,
                site: 'datadoghq.com',
                service: 'mh-ui',
                env: window.DATADOG_ENV,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 20,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask',
            });

            datadogRum.startSessionReplayRecording();
            setIsInitialized(true);
        }
    }, [isInitialized]);

    useEffect(() => {
        if (isInitialized) {
            datadogRum.setGlobalContext({
                customerInstance: clinicName || UNKNOWN_VALUE,
                personnelUsername: user?.nickname || UNKNOWN_VALUE,
                personnelRole: user?.roles.map((role) => role.toLowerCase()).join(', ') || UNKNOWN_VALUE,
            });
        }
    }, [user, clinicName, isInitialized]);
};
